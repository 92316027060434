import PreviewButton from "@/components/Common/Buttons/PreviewButton/PreviewButton";

export default {
    name: 'AdminVendorListItem',
    props: {
        orderid: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        orderamount: {
            type: String,
            default: ''
        },
        date: {
            type: String,
            default: ''
        },
        phone: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        orderstatus: {
            type: String,
            default: ''
        },

    },
    components: {
        PreviewButton
    },
    mounted() {
    }
}